export const sendEmail = (nombre, email, telefono, mensaje) => {
	const data = {
		nombre: nombre,
		email: email,
		telefono: telefono,
		mensaje: mensaje,
	};
	return new Promise((resolve, reject) => {
		fetch("/api/enviomail.php", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		}).then((rta) =>
			rta
				.json()
				.then((rta) => {
						resolve(rta);
				})
				.catch((error) => {
					reject(`Error de envío: ${error}`);
				}),
		);
	});
};
