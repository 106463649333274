export const isBot = (campoTrap) => {
	if (campoTrap === "") {
		return false;
	} else {
		return true;
	}
};

export const htmlEntities = (str) => {
	return String(str)
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;");
};

export const validName = (name) => {
	const pattern = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g; 

	if (name === "" || name.length < 2 || !pattern.test(name)) {
		return false;
	} else{
		return true;
	}
};

export const validEmail = (email) => {
	const regex =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	if (email === "" || regex.test(email) === false) {
		return false;
	} else {
		return true;
	}
};

export const validTelephone = (telephone) => {
	if(telephone === '' || (telephone !== '' && Number.isInteger(telephone))){
		return true;
	}
	return false;
};

export const validMessage = (message) => {
	if (message === "") {
		return false;
	} else {
		return true;
	}
};
