import "./logo.css";

const Logo = () => {
	return (
		<h1 className="d-flex flex-column align-items-center justify-content-center">
			<span>martín ciordia</span>
			<span>soluciones web</span>
		</h1>
	);
};

export default Logo;
