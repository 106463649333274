import "./navBar.css";
//import { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

const NavBar = () => {	
	return (
		<>
			<Navbar collapseOnSelect variant="light" expand="lg">
				<Navbar.Brand href="/">
					<h1 id="logo" className="d-flex flex-column align-items-start">
						<span>martín ciordia</span>
						<span>soluciones web</span>
					</h1>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav"/>
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="w-100 mx-lg-auto d-lg-flex justify-content-lg-end">
						<HashLink className="mx-lg-3" to="/">
							Inicio
						</HashLink>
						<HashLink className="mx-lg-3" to="/#servicios">
							Qué hago
						</HashLink>
						<HashLink className="mx-lg-3" to="/#sobre-mi">
							Sobre mí
						</HashLink>
						<HashLink className="mx-lg-3" to="/#trabajos">
							Trabajos
						</HashLink>
						<HashLink className="mx-lg-3" to="/#contacto">
							Contacto
						</HashLink>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

export default NavBar;
