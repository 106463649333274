import Banner from "../components/Banner/Banner";
import Services from "../components/Services/Services";
import About from "../components/About/About";
import Portfolio from "../components/Portfolio/Portfolio";
import Contact from "../components/Contact/Contact";

const HomeContainer = () => {
	document.title = 'martín ciordia :: Soluciones Web';
	return (
		<>
			<div>
				<Banner />
				<Services />
				<Portfolio />
				<About />				
				<Contact />
			</div>
		</>		
	);
};

export default HomeContainer;
