import { useRef } from "react";
import "./services.css";
import Metodology from "../Metodology/Metodology";

const Services = () => {

	const servicesSection = useRef(null);

	return (
		<main id="servicios" ref={servicesSection}>
			<div className="contenedor">
				<div id="que-hago-contenedor" className="w-100" role="region" aria-label="Que hago">
					<h2>Qué hago</h2>
					<p>Desarrollos desde cero para profesionales, empresas u organizaciones que
						necesiten tener presencia en Internet para ofrecer sus productos o
						servicios.</p>
					<ul className="d-flex justify-content-center align-items-start flex-wrap p-0 mx-auto mt-5">
						<li className="d-flex flex-column align-items-center">
							<h3 className="my-3">Diseños</h3>
							<p className="text-center">Actuales y acordes a las necesidades de cada solución.</p>
							<img loading="lazy" src="/img/servicios/disenios.png" alt="Ícono de diseño web" />
						</li>
						<li className="d-flex flex-column align-items-center">
							<h3 className="my-3">Desarrollos</h3>
							<p className="text-center">Uso las mejores y más modernas prácticas.</p>
							<img id="icono-desarrollo" loading="lazy" src="/img/servicios/desarrollos.png" alt="Ícono de desarrollo web" />
						</li>
						<li className="d-flex flex-column align-items-center">
							<h3 className="my-3">Responsive</h3>
							<p className="text-center">Sitios que se adaptan a todos los dispositivos.</p>
							<img loading="lazy" src="/img/servicios/responsive.png" alt="Ícono de diseño responsivo" />
						</li>
						
						<li className="d-flex flex-column align-items-center">
							<h3 className="my-3">SEO</h3>
							<p className="text-center">Sitios optimizados para un mejor posicionamiento orgánico.</p>
							<img loading="lazy" src="/img/servicios/seo.png" alt="Ícono de SEO" />
						</li>
						<li className="d-flex flex-column align-items-center">
							<h3 className="my-3">E-commerce</h3>
							<p className="text-center">Soluciones para realizar ventas online.</p>
							<img loading="lazy" src="/img/servicios/ecommerce.png" alt="Ícono de E-Commerce" />
						</li>
						<li className="d-flex flex-column align-items-center">
							<h3 className="my-3">Mantenimiento</h3>
							<p className="text-center">Mantenimiento del sitio creado o para sitios ya existentes</p>
							<img loading="lazy" src="/img/servicios/mantenimiento.png" alt="Ícono de Mantenimiento" />
						</li>
						
					</ul>
				</div>
				<Metodology />
				<section id="servicios-mantenimento" className="d-md-flex align-items-md-center mt-5">
					<div>
						<h3>Soporte y Mantenimiento</h3>
						<p>También podemos armar un plan de soporte o mantenimiento para tu sitio (uno sitio que ya exista o para el sitio que desarrollemos).</p>
						<p>El soporte o mantenimiento consta de horas mensuales (u otra frecuencia a definir) que podés contratar para realizar modificaciones en tu sitio web (textos, fotos, secciones, etc.).</p>
						<p>Si tu sitio está hecho con Wordpress, el mantenimiento también puede incluir la actualización de los diferentes componentes que se usan para armar el sitio. </p>
						<p><b>Todo esto lo definimos juntos.</b></p>
					</div>
					<div>
						<img className="d-none d-md-block" src="/img/mantenimiento.png" alt="Gente realizando mantenimiento sobre un sitio web"/>
					</div>
					
				</section>
				
			</div>
		</main>
	);
};

export default Services;
