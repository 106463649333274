import * as firebase from "firebase/app";
import { initializeApp } from "firebase/app";
/*import { getAnalytics } from "firebase/analytics";*/
import {getFirestore} from "firebase/firestore";
import {
    collection,
    getDoc,
    getDocs,
    query,
    where
} from "firebase/firestore";

/*const firebaseConfig ={
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
};*/

const firebaseConfig = {
    apiKey: "AIzaSyDoW4U1WNZY8H5_2pZ-Z0yhwY1C4n3l-B8",
    authDomain: "martinciordia-8df4d.firebaseapp.com",
    projectId: "martinciordia-8df4d",
    storageBucket: "martinciordia-8df4d.appspot.com",
    messagingSenderId: "549581555990",
    appId: "1:549581555990:web:eade5f1fcb93163b88a837",
    measurementId: "G-EK9PL32X7E"
  };

const app = initializeApp(firebaseConfig);
/*const analytics = getAnalytics(app);*/

export const getFirebase = () => {
    return app;
};

export const db = getFirestore(app);

export const getJobs = (key , operator , value)  =>{
    /*console.log("Key:" , key);
    console.log("operator:" , operator);
    console.log("value:" , value);*/
    return new Promise((resolve , reject) =>{
        const collectionQuery = key && operator && value ?
        query(collection(db , "trabajos") , where (key , operator , value))
        : collection(db , "trabajos");

        getDocs(collectionQuery)
        .then((querySnapshot) => {
            const jobs = querySnapshot.docs.map((doc) => {
                /*console.log("lo que me llega en firebase: " , doc.data());*/
                return {id: doc.id , ...doc.data()};
            });
            resolve(jobs);
        })
        .catch((error) => {
            //console.log("Entro al CATCH: " , error);
            reject(`${value ? "Error obteniendo el trabajo." : "Error obteniendo los trabajos"}: ${error}`);
        });
    });
};

export const getPreviousJobUrl = ()=>{

};

export const getNextJobUrl = () => {};
