import { Link } from "react-router-dom";
import "./jobPreview.css";

const JobPreview = (job) =>{

    return(
            <Link className="img-preview" to={`/trabajos/${job?.cliente}`}>
                <img  loading="lazy" src={`/img/trabajos-preview/${job?.imagenes.imagenPreview}`} title={`Ver el trabajo para ${job?.nombre}`} alt={job?.nombre}/>
            </Link>
    );
};
export default JobPreview;