import "./clientContainer.css";
import { useParams , useNavigate, Link} from "react-router-dom";
import Loader from "../components/Loader/Loader"
import { useEffect, useState } from "react";
import { getJobs } from "../Services/firebase";
import { HashLink } from "react-router-hash-link";

const ClientContainer = () => {

	const {clientId} = useParams();
	const [showLoader , setShowLoader] = useState(true);
	const [error , setError] = useState(false);
	const navigate = useNavigate();
	const [job , setJob] = useState(undefined);

	function metaKeywordsAdder (value){
		const keywordsElement = document.querySelector('meta[name~="keywords"][content]');
		const newKeywords = document.querySelector('meta[name~="keywords"][content]').content + ' , ' + value;
		keywordsElement.setAttribute("content", newKeywords);
	}

	function metaAdder (queryProperty , value) {
		let element = document.querySelector(`meta[${queryProperty}]`);
		element.setAttribute("content", value);
	};

	document.body.scrollTop = 0;

	useEffect(() => {	
		getJobs("cliente" , "==" , clientId)
		.then((data) => {
			if(data[0].cliente){
				setJob(data[0]);			
				document.title = data[0].nombre + ' | martín ciordia :: Soluciones Web';
				metaAdder('name="description"' , data[0].metaDescription);
				metaAdder('property="og:title"' , data[0].metalOggTitle);
				metaAdder('property="og:description"' , data[0].metaDescription);
				metaAdder('property="og:url"' , 'https://martinciordia.com.ar/trabajos/' + data[0].cliente);
				metaKeywordsAdder(`${data[0].nombre}`);
				setShowLoader(false);
				setError(false);
			}else{
				navigate("/404");
			}
		})
		.catch(() => {
			setError(true);
			setShowLoader(false);				
		});

		return () => {
			setJob(undefined);
			setError(false);
			setShowLoader(true);
		};
	} , [clientId , navigate]);	

	return (		
		<>
			<section id="trabajo-detalle">
				<div className="contenedor">
					{showLoader && 
					<div className="d-flex justify-content-center align-items-center">
							<Loader/>
					</div>}	
					{job && 
					<>
						<h2 id="cliente-titulo">{job?.nombre}</h2>
						<div className="w-100 d-flex flex-column align-items-start flex-md-row justify-content-between">
							<div className="info-box">
								<h3>Cliente</h3>
								<p id="cliente-descripcion">{job?.servicio}</p>
							</div>
							<div className="info-box mt-4 mt-md-0">
								<h3>Características del sitio</h3>
								<ul className="p-0 m-0 mb-5">
									<li className="mb-2">Sitio {job?.detalles.tipo}</li>
									{job?.detalles.wordpress && <li className="mb-2">Hecho en Wordpress.</li>}
									{job?.detalles.mantenimiento ? <li  className="mb-2">Mantenimiento contratado.</li> : <li className="mb-2">Sin mantenimiento contratado.</li>}							
									{job?.detalles.multilenguaje ? <li className="mb-5">Más de un idioma.</li> : <li>Un solo idioma.</li>}
								</ul>
								{job?.mostrarUrl ? <a className="mt-3 w-100 text-center" target="_blank" rel="nofollow noopener external noreferer" href={job?.url} id="btn-link-sitio-cliente">Ir al sitio</a> : <span>Sitio no disponble.</span>}
							</div>
						</div>
						<div className="d-flex flex-column align-items-center flex-lg-row justify-content-between my-5">
							<img loading="lazy" className="my-5" src={`/img/trabajos/${job?.imagenes.imagenDetalle1}`} alt={job?.imagenes.descripcion1} />
							<img loading="lazy" src={`/img/trabajos/${job?.imagenes.imagenDetalle2}`} alt={job?.imagenes.descripcion2} />
						</div>
					</>}	
					{error && 
						<div className="d-flex flex-column align-items-center">
							<h2 id="titulo-error">Ups... Algo falló.</h2>
							<p className="mt-4 texto-error">El trabajo que buscás no existe o no se pudo cargar la información.</p>
							<p className="mb-4 texto-error">Revisá el texto ingresado o intentalo de nuevo en unos minutos.</p>
							<HashLink className="btn-volver" to="/#trabajos">Volver a los trabajos</HashLink>
						</div>		
					}
				</div>			
			</section>
		</>		
	);
};

export default ClientContainer;
