import "./metodology.css";

const Metodology = () => {
	return (
		<section id="metodologia">
			<h3>Cómo lo hago</h3>
			<p>
				Estos son los pasos que daremos para crear un sitio web te guste y se ajuste a lo que necesitás.
			</p>
			<div id="pasos">
				<ol className="p-0 d-flex flex-wrap justify-content-start">
					<li className="d-flex flex-column align-items-start mx-auto">
						<h4 id="pasos-analisis"><span>1</span>Análisis</h4>
						<p>
							Realizamos un primer contacto para poder determinar las necesidades y
							los objetivos con respecto al sitio web. Acá podemos definir si conviene hacerlo con Wordpress.<br/>							
							<span><b>Te voy a hacer muchas preguntas.</b></span>
						</p>
					</li>					
					<li className="d-flex flex-column align-items-start mx-auto">
						<h4><span>2</span>Relevamiento</h4>
						<p>
							Si ya tenés ejemplos o algún modelo de lo que querés que sea tu
							sitio, lo analizamos. Si no, basándonos en el análisis, recopilamos todo lo que querés que tenga tu sitio.
						</p>
					</li>
					<li className="d-flex flex-column align-items-start mx-auto">
						<h4><span>3</span>Aportes</h4>
						<p>
							Luego del relevamiento realizo aportes o sugerencias para poder
							comenzar a delinear una primera idea del sitio.
						</p>
					</li>
					<li className="d-flex flex-column align-items-start mx-auto">
						<h4><span>4</span>Contenido</h4>
						<p>
							Una vez definido un primer boceto de lo que sería el sitio,
							necesito que me entregues todo el contenido que va a presentar el
							sitio (textos, imagenes, etc...).
						</p>
					</li>
					<li className="d-flex flex-column align-items-start mx-auto">
						<h4><span>5</span>Diseño</h4>
						<p>
							Con el primero boceto y todo el contenido del sitio realizo una
							muestra de lo que sería el sitio para que lo evalúes y apruebes.
						</p>
					</li>
					<li className="d-flex flex-column align-items-start mx-auto">
						<h4><span>6</span>Desarrollo</h4>
						<p>
							Con la muestra aprobada comienza la etapa del desarrollo, siempre
							en contacto para que puedas ir viendo los avances del mismo.
						</p>
					</li>
					<li className="d-flex flex-column align-items-start align-items-md-center mx-auto">
						<h4 className="text-center"><span>7</span>Publicación</h4>
						<p className="text-md-center">
							Una vez finalizado el desarrollo publico el sitio y lo dejo
							online. ¡Listo! Ya tenés tu sitio web :-D
						</p>
					</li>
				</ol>
			</div>
			<div id="metodologia-extra">
				<h5> Extras: Hosting y dominio.</h5>				
				<p>
					Si no contás con alguno de estos elementos, basado en el Análisis del <a id="link-ancla" href="#pasos-analisis">punto 1</a>,
					puedo ayudarte a definir y contratar lo necesario.
				</p>
			</div>
		</section>
	);
};

export default Metodology;
