import { HashLink } from "react-router-hash-link";
import "./footerNavBar.css";

const FooterNavBar = () => {
    return(
        <ul id="footer-navbar" className="p-0 m-0 d-flex flex-column aling-items-center flex-sm-row justify-content-center">
            <li><HashLink  to="/#inicio">Inicio</HashLink></li>
            <li><HashLink to="/#servicios">Qué hago</HashLink></li>
            <li><HashLink to="/#sobre-mi">Sobre mí</HashLink></li>
            <li><HashLink to="/#trabajos">Trabajos</HashLink></li>
            <li><HashLink to="/#contacto">Contacto</HashLink></li>
        </ul>
    );
}

export default FooterNavBar;