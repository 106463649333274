import "./contact.css";
import { Form, Button } from "react-bootstrap";
import { useState , useRef, useEffect } from "react";
import Loader from "../Loader/Loader";
import { sendEmail } from "../../Services/sendEmail";
import {
	isBot,
	validName,
	validEmail,
	validMessage,
	validTelephone,
} from "../../Services/formValidation";

const Contact = () => {
	const attentionFieldMessageRef = useRef(null);
	const showResultMessage = useRef(null);
	const [sendingEmail , setSendingEmail] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [campoTrap, setCampoTrap] = useState("");
	const [campoNombre, setCampoNombre] = useState("");
	const [campoEmail, setCampoEmail] = useState("");
	const [campoTelefono, setCampoTelefono] = useState("");
	const [campoMensaje, setCampoMensaje] = useState("");
	const [btnEnviarDisabled, setBtnEnviarDisabled] = useState(true);
	const [attentionFieldMessage, setAttentionFieldMessage] = useState(false);
	const [emptyFieldMessage, setEmptyFieldMessage] = useState({
		nombre: false,
		email: false,
		telefono: false,
		mensaje: false,
	});
	const [successMesagge, setSuccessMesagge] = useState(false);
	const [errorMesagge, setErrorMesagge] = useState(false);

	const validFields = (
		campoNombre,
		campoEmail,
		campoTelefono,
		campoMensaje,
		) => {
		if (
			validName(campoNombre) &&
			validEmail(campoEmail) &&
			(campoTelefono !== '' ? validTelephone(parseInt(campoTelefono)) : true) &&
			validMessage(campoMensaje)
		) {
			setBtnEnviarDisabled(false);
			return true;
		} else {
			setBtnEnviarDisabled(true);
			return false;
		}
	};

	const clearFields = () =>{
		setCampoNombre("");
		setCampoEmail("");
		setCampoTelefono("");
		setCampoMensaje("");
		setCampoTrap("");
	};

	const clearFormMessages = () =>{
		setAttentionFieldMessage(false);
		setErrorMesagge(false);
		setSuccessMesagge(false);
	};

	const handleOutputSuccess = () => {
		setAttentionFieldMessage(false);
		setErrorMesagge(false);
		setEmptyFieldMessage({
			nombre: false,
			email: false,
			mensaje: false,
		});
		clearFields();
		setSuccessMesagge(true);
		setBtnEnviarDisabled(false);
	};

	const handleOutputFieldErrors = (errores) => {
		setAttentionFieldMessage(true);
		setBtnEnviarDisabled(true);
		if (errores['nombre']) {
			setEmptyFieldMessage({
				...emptyFieldMessage,
				nombre: true,
			});
		}
		if (errores['email']) {
			setEmptyFieldMessage({
				...emptyFieldMessage,
				email: true,
			});
		}
		if(errores['telefono']){
			setEmptyFieldMessage({
				...emptyFieldMessage,
				telefono: true
			});
		}
		if (errores['mensaje']) {
			setEmptyFieldMessage({
				...emptyFieldMessage,
				mensaje: true,
			});
		}
	};
	const handleOutputError = () => {
		setErrorMesagge(true);
		setAttentionFieldMessage(false);
		setSuccessMesagge(false);
		setSendingEmail(false);
		setBtnEnviarDisabled(true);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!isBot(campoTrap)) {
			if (validFields(campoNombre, campoEmail, campoTelefono, campoMensaje)) {
				try{
					sendEmail(
					campoNombre,
					campoEmail,
					campoTelefono,
					campoMensaje,
					)
					.then(rta =>{
						console.log("RTA: " , rta);
						if(rta.status === 0){
							handleOutputSuccess();
							clearFields();
							setSendingEmail(false);
						}else if(rta.status === 1){
							handleOutputFieldErrors(rta.errores); 
							setSendingEmail(false);
						}else{
							//status 2
							handleOutputError();
							setSendingEmail(false);
						}						
					})
					.catch(error => {
						handleOutputError();
						setSendingEmail(false);
					});
				} catch{
					handleOutputError();
					setSendingEmail(false);
				}
			} else {
				setAttentionFieldMessage(true);
				setSuccessMesagge(false);
				setErrorMesagge(false);
				setBtnEnviarDisabled(true);
				setSendingEmail(false);
			}
		}	
	};

	useEffect(() => {
		if(successMesagge){
			showResultMessage.current.scrollIntoView();
		}
	} , [successMesagge]);

	useEffect(() => {
		if(errorMesagge){
			showResultMessage.current.scrollIntoView();
		}
	} , [errorMesagge]);

	useEffect(() => {
		if(attentionFieldMessage){
			attentionFieldMessage.current.scrollIntoView();
		}
	} , [attentionFieldMessage]);

	useEffect(() => {
		if(sendingEmail){
			setShowLoader(true);
		}
	} , [sendingEmail]);

	return (
		<section id="contacto">
			<div className="contenedor">
				<h2>Contacto</h2>
				<p>¡Hablemos de tu sitio web!</p>
				<p className="d-flex justify-content-start align-items-center flex-wrap">
					Completá el formulario o &nbsp;<a
						id="btn-enviar-mail"
						href="mailto:yo@martinciordia.com.ar"
						target="_blank"
						rel="nofollow external noopener noreferrer"
					>
						enviame un e-mail
					</a>&nbsp; y estamos listos para empezar.
				</p>
				<div className="d-flex flex-column align-items-center">
					<div id="caja-form">
						<p id="help-obligatorios">
							<span className="asterisco-form">(*)</span> Campos obligatorios.
						</p>
						<Form
							noValidate
							className="d-flex flex-column align-items-start"
						>
							{attentionFieldMessage && (
								<p id="help-atencion" ref={attentionFieldMessageRef}>Algún campo necesita atención.</p>
							)}
							<Form.Group className="d-none">
								<Form.Control
									id="trap"
									aria-hidden="true"
									type="text"
									value={campoTrap}
									name="trap"
									onChange={(e) => setCampoTrap(e.target.value)}
								/>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label htmlFor="nombre">
									Nombre <span className="asterisco-form">*</span>
								</Form.Label>
								<Form.Control
									id="nombre"
									type="text"
									className={emptyFieldMessage.nombre && `campo-invalido`}
									value={campoNombre}
									name="nombre"
									required
									onChange={(e) => {
										setCampoNombre(e.target.value.trim());
										validName(e.target.value.trim()) ? setEmptyFieldMessage({
											...emptyFieldMessage,
											nombre: false,
										}) : setEmptyFieldMessage({
											...emptyFieldMessage,
											nombre: true,
										});
										validFields(campoNombre , campoEmail , campoTelefono , campoMensaje) ? setBtnEnviarDisabled(false) : setBtnEnviarDisabled(true);
									}}
								/>
								{emptyFieldMessage.nombre && (
									<Form.Text className="mensaje-campo-vacio" muted>
										No acepta menos de 2 letras. Solo acepta letras.
									</Form.Text>
								)}
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label htmlFor="email">
									Email <span className="asterisco-form">*</span>
								</Form.Label>
								<Form.Control
									type="email"
									id="email"
									className={emptyFieldMessage.email && `campo-invalido`}
									value={campoEmail}
									name="email"
									required
									onChange={(e) => {
										setCampoEmail(e.target.value.trim());
										validEmail(e.target.value.trim()) ? setEmptyFieldMessage({
											...emptyFieldMessage,
											email: false,
										}) : setEmptyFieldMessage({
											...emptyFieldMessage,
											email: true,
										});
										validFields(campoNombre , e.target.value.trim() , campoTelefono , campoMensaje) ? setBtnEnviarDisabled(false) : setBtnEnviarDisabled(true);
									}}
								/>
								{emptyFieldMessage.email && (
									<Form.Text className="mensaje-campo-vacio" muted>
										Ingrese un email válido.
									</Form.Text>
								)}
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label htmlFor="telefono">Teléfono</Form.Label>
								<Form.Control
									id="telefono"
									type="text"
									aria-describedby="help-telefono"
									className={emptyFieldMessage.telefono && `campo-invalido`}
									value={campoTelefono}
									pattern="[0-9]*"
									name="telefono"
									onChange={(e) =>
										setCampoTelefono((v) =>
											e.target.validity.valid ? e.target.value : v,
										)
									}
									onBlur={ () => {
										if(campoTelefono){
											setCampoTelefono(campoTelefono.trim());
											validTelephone(parseInt(campoTelefono)) ? setEmptyFieldMessage({
											...emptyFieldMessage,
											telefono: false,
										}) : setEmptyFieldMessage({
											...emptyFieldMessage,
											telefono: true,
										});
											validFields(campoNombre , campoEmail , campoTelefono , campoMensaje) ? setBtnEnviarDisabled(false) : setBtnEnviarDisabled(true);
										}else{
											setCampoTelefono('');
										}	
									}}
								/>
								<Form.Text id="help-telefono" className="m-0" muted>
									Ingresá sólo números.
								</Form.Text>
								{emptyFieldMessage.telefono && (
									<div className="m-0 p-0 d-flex flex-column align-items-start">
										<br></br>
									<Form.Text className="mensaje-campo-vacio" muted>
										Este campo debe completarse.
									</Form.Text>
									</div>
								)}
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label htmlFor="mensaje">
									Mensaje <span className="asterisco-form">*</span>
								</Form.Label>
								<Form.Control
									id="mensaje"
									as="textarea"
									rows={5}
									className={emptyFieldMessage.mensaje && `campo-invalido`}
									value={campoMensaje}
									name="mensaje"
									required
									onChange={(e) => {
										setCampoMensaje(e.target.value.trim());
										validMessage(e.target.value.trim()) ? setEmptyFieldMessage({
											...emptyFieldMessage,
											mensaje: false,
										}) : setEmptyFieldMessage({
											...emptyFieldMessage,
											mensaje: true,
										});
										validFields(campoNombre , campoEmail , campoTelefono , e.target.value.trim()) ? setBtnEnviarDisabled(false) : setBtnEnviarDisabled(true);
									}}
								/>
								{emptyFieldMessage.mensaje && (
									<Form.Text className="mensaje-campo-vacio" muted>
										Este campo debe completarse.
									</Form.Text>
								)}
							</Form.Group>
							{sendingEmail ? (
								<Loader />
							) : (
								<Button
									id="btn-enviar"
									className="align-self-end"
									variant="primary"
									type="submit"
									onClick={(e)=>{
										setSendingEmail(true);
										clearFormMessages();
										handleSubmit(e);
									}}
									disabled={btnEnviarDisabled}
								>
									Enviar
								</Button>
							)}
						</Form>
					</div>
					<div
						id="caja-mensajes"
						className="d-flex flex-column align-items-center align-self-start m-0"
					>
						{successMesagge && (
							<p className="text-center m-0 w-100" id="mensaje-success" ref={showResultMessage}>
								¡Mensaje enviado! En breve te estaré contactando.
							</p>
						)}
						{errorMesagge && (
							<div id="mensaje-error" className="text-center mt-0 w-100" ref={showResultMessage}>
								<p className="m-0 w-100">
									Hubo un error al enviar el mensaje. Por favor intentalo
									nuevamente.
								</p>
								<p className="m-0 w-100">
									Si el problema persiste, podés enviarme un email: <a href="mailto:yo@martinciordia.com.ar">yo@martinciordia.com.ar</a>
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
