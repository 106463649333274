import FooterNavBar from "../components/FooterNavBar/FooterNavBar";
import Logo from "../components/Logo/Logo";
import "./footer.css";
const Footer = () => {
	const fechaActual = new Date();
	const fechaInicio = "2017";
	return (
		<div id="div-footer" className="contenedor">
			<div id="footer-info" className="d-flex flex-column align-items-center flex-lg-row justify-content-lg-between mx-auto">
				<Logo />
				<div id="footer-contacto">
					<a href="mailto:yo@martinciordia.com.ar">
						yo@martinciordia.com.ar
					</a>
				</div>
				<div id="footer-menu">
					<FooterNavBar/>
				</div>			
			</div>
			<div id="footer-pie" className="d-flex flex-column align-items-center flex-md-row justify-content-md-around">
			<p className="w-100 w-md-50">
				&copy; martín ciordia {fechaInicio}
				&nbsp;- {fechaActual.getFullYear()}
				&nbsp; &middot; Todos los derechos reservados.
			</p>
			<p className="w-100 w-md-50">Sitio realizado con <a target="_blank" rel="nofollow noreferrer noopener external" href="https://reactjs.org">ReactJS</a></p>
			</div>
			
		</div>
	);
};

export default Footer;
