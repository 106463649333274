import "./portfolio.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getJobs } from "../../Services/firebase";
import Loader from "../Loader/Loader";
import JobPreview from "../JobPreview/JobPreview";

const Portfolio = () => {
	const [listJobs , setListJobs] = useState([]);
	const {name} = useParams();
	const [errorMessage , setErrorMessage] = useState(undefined);
	
	useEffect(() => {
		getJobs("publicar" , "==" , true)
		.then((jobs) => {
			setListJobs(jobs.sort(function(a, b) {
				return b.orden - a.orden;
			  }));
		})
		.catch((error) => {
			setErrorMessage(error);
		});
		return() => {
			setListJobs([]);
			setErrorMessage(undefined);
		}
	}, [name]);

	if (listJobs.length === 0 && !errorMessage){
		return (
			<section id="trabajos" className="fondo-color">
				<div className="contenedor centrado">
					<h2>Trabajos</h2>
					<Loader className="align-self-center" />
				</div>
			</section>
		);
	}else if(errorMessage){
		return (
			<section id="trabajos" className="fondo-color">
				<div className="contenedor">
					<h2>Trabajos</h2>
					<p>{errorMessage}</p>
					<p>Por favor intentalo de nuevo en unos minutos.</p>
				</div>
			</section>
		);
	}else{		
		return (
			<section id="trabajos" className="fondo-color">
				<div className="contenedor d-flex flex-column">
					<h2>Trabajos</h2>
					<ul className="p-0 mx-auto d-flex flex-wrap justify-content-between align-items-center ">
						{listJobs.map((job) => (
							<li 
							key={job?.id}
							className="d-flex justify-content-center align-items-center mx-auto mb-5">
								<JobPreview {...job}/>
							</li>
						))}
					</ul>
				</div>
			</section>
		);
	}	
};

export default Portfolio;
