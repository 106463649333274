import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./views/Header";
import HomeContainer from "./views/HomeContainer";
import ClientContainer from "./views/ClientContainer";
import PageNotFoundContainer from "./views/PageNotFoundContainer";
import Footer from "./views/Footer";
import BackToTop from "./components/BackToTop/BackToTop";

function App() {
	return (
		<div>			
			<BrowserRouter>
				<header>
					<Header />
				</header>
				<Routes>
					<Route path="/" element={<HomeContainer />} />
					<Route path="/trabajos/:clientId" element={<ClientContainer />} />
					<Route path="*" element={<PageNotFoundContainer />} />
				</Routes>
				<footer>
					<Footer />
				</footer>
				<BackToTop />
			</BrowserRouter>
		</div>
	);
}

export default App;
