import "./pageNotFound.css";
import { HashLink } from "react-router-hash-link";

const PageNotFound = () => {
	return (
		<section>
			<div className="contenedor d-flex- flex-column align-items-center">
				<h2 id="titulo-404" className="text-center">Página no encontrada</h2>
				<p className="texto-error">
					La página a la que intentás acceder no está disponible o no existe.
				</p>
				<p className="texto-error">Revisá el nombre e intentalo nuevamente.</p>
				<div className="d-flex justify-content-center align-items-center">
					<HashLink to="/#inicio" title="Volver al inicio" className="mr-2 btn-volver">
						Inicio
					</HashLink>
					<HashLink to="/#trabajos" title="Ver mis trabajos" className="ml-2 btn-volver">
						Trabajos
					</HashLink>
				</div>
			</div>
		</section>
	);
};

export default PageNotFound;
