import { useState , useEffect } from "react";
import "./backToTop.css";

const BackToTop = () => {

	const [showButton , setShowButton] = useState(false);

	const scrollToTop = () => {
		document.body.scrollTop = 0;
	};

	const toggleVisible = () =>{
			if(document.body.scrollTop > 200){
				setShowButton(true);
			}else{
				setShowButton(false);
			}
	};

	window.addEventListener("scroll", toggleVisible , { capture: true });	

	useEffect(() => {
		window.removeEventListener("scroll", toggleVisible , { capture: true });
		window.addEventListener("scroll", toggleVisible , { capture: true });	
		
		return () => {
			window.removeEventListener("scroll", toggleVisible , { capture: true });
			setShowButton(false);
		}
    }, []);

	return (
		<div className="m-0 p-0">
			{showButton && (<button id="myBtn" title="Ir arriba" onClick={scrollToTop}></button>)}
		</div>
	);
};

export default BackToTop;