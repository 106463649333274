import "./banner.css";
import { Carousel } from "react-bootstrap";

const Banner = () => {
	return (
		<>
			<video
				id="background-video"
				autoPlay
				loop
				muted
				poster="/img/banner1-576.jpg"
			>
				<source src="/video/banner-video.mp4" type="video/mp4" />
				<source src="/video/banner-video.ogg" type="video/ogg" />
			</video>
			<Carousel>
				<Carousel.Item id="carousel-1">
					<Carousel.Caption>
						<h2>Soluciones completas</h2>
						<p>Desarrollos exclusivos hechos desde cero.</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item id="carousel-2">
					<Carousel.Caption>
						<h2>Trabajos personalizados</h2>
						<p>Según el producto o servicio que quieras ofrecer.</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item id="carousel-3">
					<Carousel.Caption>
						<h2>Soporte y mantenimiento</h2>
						<p>Para el sitio terminado o para desarrollos ya existentes.</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item id="carousel-4">
					<Carousel.Caption>
						<h2>No soy una agencia</h2>
						<p>Hablás directamente con el que realiza el trabajo.</p>
					</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
		</>
	);
};

export default Banner;
