import "./about.css";

const About = () => {

	return (
		<section id="sobre-mi">
			<div className="contenedor">
				<h2>Sobre mí</h2>
				<div className="d-md-flex align-items-md-center">
					<div id="about-texto">
						<p>Soy un desarrollador freelance ubicado en la Ciudad Autónoma de Buenos Aires, Argentina.</p>
						<p>Comencé con el desarrollo web de forma autodidacta y realizando cursos puntuales sobre alguna tecnología o herramienta. En el año 2018 inicié la carrera de Desarrollo y Diseño Web de la <a href="https://davinci.edu.ar" target="_blank" rel="external nofollow noopener noreferrer">Escuela Multimedial de Arte <b>Da Vinci</b><span id="link-externo"></span></a>, la cual finalicé en el año 2021.</p>
						<p> Esto me permitió poder incorporar un conocimiento mas amplio, abarcando no solo más herramientas de desarrollo, si no también de diseño web, SEO (posicionamiento orgánico de sitios), análisis de lenguaje y contenido, y metodologías de trabajo.</p>
						
						<p>Esta carrera, junto con los trabajos que fui haciendo me permitieron ir perfeccionando mis desarrollos, como así también ir incorporando nuevas tecnologías y herramientas a los mismos.</p>
					</div>
					<div id="imagen-martin" className="d-none d-md-block">
						<img src="/img/yo-about.png" alt="Caricatura del rostro de Martín Ciordia."/>
					</div>
				</div>
				
				<h3>Conocimientos</h3>
				<p className="mb-5">Si querés saber sobre mis conocimientos y con que herramientas trabajo, acá hay un resumen:</p>
				<div id="conocimientos-lista" className="d-flex flex-column align-items-center flex-md-row align-items-md-start justify-content-md-around" aria-labelledby="help-conocimientos">
					<div className="w-100 d-flex flex-column align-items-start">
						<div className="d-flex align-items-center">
							<span className="conocimiento-nombre" aria-labelledby="nivel-html5" >HTML5</span>
							<div className="progress-bar">
								<div className="nivel-90"></div>
							</div>
							<span id="nivel-html5" className="d-none fontCero">Conocimiento Alto</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-ccs3">
							<span className="conocimiento-nombre">CCS3</span>
							<div className="progress-bar">
								<div className="nivel-90"></div>
							</div>
							<span id="nivel-css3" className="d-none fontCero">Conocimiento Alto</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-bootstrap">
							<span className="conocimiento-nombre">Bootstrap</span>
							<div className="progress-bar">
								<div className="nivel-90"></div>
							</div>
							<span id="nivel-bootstrap" className="d-none fontCero">Conocimiento muy alto</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-flexbox">
							<span className="conocimiento-nombre">Flexbox</span>
							<div className="progress-bar">
								<div className="nivel-90"></div>
							</div>
							<span id="nivel-flexbox" className="d-none fontCero">Conocimiento muy alto</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-grid">
							<span className="conocimiento-nombre">Grid</span>
							<div className="progress-bar">
								<div className="nivel-medio"></div>
							</div>
							<span id="nivel-grid" className="d-none fontCero">Conocimiento bajo</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-wordpress">
							<span className="conocimiento-nombre">Wordpress</span>
							<div className="progress-bar">
								<div className="nivel-medio"></div>
							</div>
							<span id="nivel-wordpress" className="d-none fontCero">Conocimiento Medio</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-javascript">
							<span className="conocimiento-nombre">Javascript</span>
							<div className="progress-bar">
								<div className="nivel-alto"></div>
							</div>
							<span id="nivel-javascript" className="d-none fontCero">Conocimiento Medio</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-git">
							<span className="conocimiento-nombre">Git</span>
							<div className="progress-bar">
								<div className="nivel-medio"></div>
							</div>
							<span id="nivel-git" className="d-none fontCero">Conocimiento Medio</span>
						</div>
					</div>
					<div  className="w-100 d-flex flex-column align-items-start">
						<div className="d-flex align-items-center" aria-labelledby="nivel-jquery">
							<span className="conocimiento-nombre">Jquery</span>
							<div className="progress-bar">
								<div className="nivel-medio"></div>
							</div>
							<span id="nivel-jquery" className="d-none fontCero">Conocimiento Medio</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-php">
							<span className="conocimiento-nombre">Php</span>
							<div className="progress-bar">
								<div className="nivel-medio"></div>
							</div>
							<span id="nivel-php" className="d-none fontCero">Conocimiento Medio</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-laravel">
							<span className="conocimiento-nombre">Laravel</span>
							<div className="progress-bar">
								<div className="nivel-alto"></div>
							</div>
							<span id="nivel-laravel" className="d-none fontCero">Conocimiento Alto</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-mysql">
							<span className="conocimiento-nombre">Mysql</span>
							<div className="progress-bar">
								<div className="nivel-medio"></div>
							</div>
							<span id="nivel-mysql" className="d-none fontCero">Conocimiento Medio</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-firebase">
							<span className="conocimiento-nombre">Firebase</span>
							<div className="progress-bar">
								<div className="nivel-bajo"></div>
							</div>
							<span id="nivel-firebase" className="d-none fontCero">Conocimiento Bajo</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-vuejs">
							<span className="conocimiento-nombre">VueJs</span>
							<div className="progress-bar">
								<div className="nivel-medio"></div>
							</div>
							<span id="nivel-vuejs" className="d-none fontCero">Conocimiento Medio</span>
						</div>
						<div className="d-flex align-items-center" aria-labelledby="nivel-reactjs">
							<span className="conocimiento-nombre">ReactJs</span>
							<div className="progress-bar">
								<div className="nivel-medio"></div>
							</div>
							<span id="nivel-reactjs" className="d-none fontCero">Conocimiento Medio</span>
						</div>
					</div>

					<p id="help-conocimientos" className="d-none fontCero">Lista de tecnologías y herramientas que dominio y el grado de dominio</p>
					
				</div>
			</div>
		</section>
	);
};

export default About;
